<template>
  <div class="app flex-row" id ="main_container">

    <AppHeader fixed>
      <b-link class="navbar-brand" to="#">
        <img class="navbar-brand-full" src="img/logo.png" width="89" height="25" alt="PIA Logo">

      </b-link>

      <b-navbar-nav class="ml-auto pr-3">
        <DefaultHeaderDropdownAccount />
      </b-navbar-nav>
    </AppHeader>
      <b-tabs pills card vertical class="pt-5 w-100">
        <b-tab title="Moderation de cultures">
          <b-card no-body>
            <b-tabs pills class ="h-100 ">
              <b-tab class="p-0" title="Cultures PIA" @click="switchCathegorie('piaCultures')">
    
              </b-tab>
              <b-tab class="p-0" title="Approuvées" @click="switchCathegorie('approuvedCultures')">
    
              </b-tab>
              <b-tab class="p-0" title="En Attente" @click="switchCathegorie('unHandledCultures')">
                
              </b-tab>
              <b-tab class="p-0" title="Refusées" @click="switchCathegorie('refusedCultures')">
                
              </b-tab>
              <div class="h-100">
                <div v-if="fetchingCultures" class="no_data_container">
                  <b-spinner></b-spinner><br>
                  <p class = "ml-2">Chargement...</p>
                </div>
                <div v-else-if="!cultures[cathegories] || cultures[cathegories].length == 0" class="no_data_container">
                  <p class="m-0 w-auto">Aucune donnée</p>
                </div>
                <!-- TODO: les differencier -->
                <b-tabs v-else card pills no-body vertical class ="h-100 shfsd" ref="displayedCultureTabs">
                  <b-tab v-for="culture of cultures[cathegories]" :title="culture.name" @click="switchDisplayedCulture(culture)" class="p-0"></b-tab>
                  <b-card class="m-0 border-0" ref="cultureContainer">
                    <div v-if="Object.keys(displayedCulture).length > 0" :class="{visibility: 'hidden'}" class="display_culture_container">
                      <GeneratedCultureContainer :culture="displayedCulture" />
                    </div>
                  </b-card>
                  <div class="pb-3 button_container" ref="buttonContainer">
                    <b-button v-if="actions.includes('dismiss')" :disabled="operationInProgress" variant="danger" @click="denayCulture(displayedCulture)">Rejeter</b-button>
                    <b-button v-if="actions.includes('approve')" :disabled="operationInProgress" variant="success" @click="acceptCulture(displayedCulture)">Approuver</b-button>
                  </div>
                </b-tabs>
              </div>
            </b-tabs>
          </b-card>
        </b-tab>
        <b-tab title="Téléverser">
          <b-tabs v-model="adminTapOpen">
            <b-tab active>
              <div slot="title" class="text-dark">
                <i class="fa fa-leaf"></i> Cultures
              </div>
              <b-card no-body>
                <b-tabs pills card vertical>
                  <b-tab title="Créer une culture" active>
                    <div class="card-header-actions mb-2 mt-0">
                      <b-link variant="primary" class="btn btn-primary" :href="csvTemplateCreateCultureUrl"
                        download="culture.xlsx">
                        <i class="fa fa-download"></i>
                        Fichier CSV exemple
                      </b-link>
                    </div>
                    <b-input-group class="mb-3">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-file-excel-o"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-file :state="csvFileCreateCultureState" v-model="csvCreateCultureFile"
                        placeholder="Ajoutez vos cultures à partir d'un fichier..."
                        drop-placeholder="Déposez votre fichier ici..."
                        @change="csvCreateCultureFileChanged"></b-form-file>
                      <b-form-invalid-feedback :state="csvFileCreateCultureState == null">
                        Mauvais format du fichier
                      </b-form-invalid-feedback>
                    </b-input-group>
                    <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitCreateCultureFile"
                      :fetching="fetchingCreateCultureFile">
                      Créer des cultures
                    </button-spinner>
                  </b-tab>
                  <b-tab title="Modifier une culture">
                    <b-card-text>Tab contents 2</b-card-text>
                  </b-tab>
                  <b-tab title="Association cult-etape">
                    <b-card-text>Tab contents 3</b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card>
            </b-tab>
            <b-tab>
              <div slot="title" class="text-dark">
                <i class="fa fa-leaf"></i> Etapes de cultures
              </div>
              <b-card no-body>
                <b-tabs pills card vertical>
                  <b-tab title="Créer une étape de culture" active>
                    <div class="card-header-actions mb-2 mt-0">
                      <b-link variant="primary" class="btn btn-primary" :href="csvTemplateCreateCultureStepUrl"
                        download="culture_step.xlsx">
                        <i class="fa fa-download"></i>
                        Fichier CSV exemple
                      </b-link>
                    </div>
                    <b-input-group class="mb-3">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-file-excel-o"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-file :state="csvFileCreateCultureStepState" v-model="csvCreateCultureStepFile"
                        placeholder="Ajoutez vos étapes de cultures à partir d'un fichier..."
                        drop-placeholder="Déposez votre fichier ici..."
                        @change="csvCreateCultureStepFileChanged"></b-form-file>
                      <b-form-invalid-feedback :state="csvFileCreateCultureStepState == null">
                        Mauvais format du fichier
                      </b-form-invalid-feedback>
                    </b-input-group>
                    <button-spinner variant="success" type="submit" class="px-4"
                      @click="onSubmitCreateCultureStepFile" :fetching="fetchingCreateCultureStepFile">
                      Créer des étapes de culture
                    </button-spinner>
                  </b-tab>
                  <b-tab title="Modifier une étape de culture">
                    <b-card-text>Tab contents 2</b-card-text>
                  </b-tab>
                  <b-tab title="Association étape-activité">
                    <b-card-text>Tab contents 3</b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card>
            </b-tab>
            <b-tab>
              <div slot="title" class="text-dark">
                <i class="fa fa-leaf"></i> Activites
              </div>
              <b-card no-body>
                <b-tabs pills card vertical>
                  <b-tab title="Créer une Activité" active>
                    <div class="card-header-actions mb-2 mt-0">
                      <b-link variant="primary" class="btn btn-primary" :href="csvTemplateCreateActivityUrl"
                        download="activity.xlsx">
                        <i class="fa fa-download"></i>
                        Fichier CSV exemple
                      </b-link>
                    </div>
                    <b-input-group class="mb-3">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-file-excel-o"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-file :state="csvFileCreateActivityState" v-model="csvCreateActivityFile"
                        placeholder="Ajoutez vos cultures à partir d'un fichier..."
                        drop-placeholder="Déposez votre fichier ici..."
                        @change="csvCreateActivityFileChanged"></b-form-file>
                      <b-form-invalid-feedback :state="csvFileCreateActivityState == null">
                        Mauvais format du fichier
                      </b-form-invalid-feedback>
                    </b-input-group>
                    <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitCreateActivityFile"
                      :fetching="fetchingCreateActivityFile">
                      Créer des cultures
                    </button-spinner>
                  </b-tab>
                  <b-tab title="Modifier une activité">
                    <b-card-text>Tab contents 2</b-card-text>
                  </b-tab>
                  <b-tab title="Association activité-intrant/equipement">
                    <b-card-text>Tab contents 3</b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card>
            </b-tab>
            <b-tab>
              <div slot="title" class="text-dark">
                <i class="fa fa-leaf"></i> Intrants
              </div>
            </b-tab>
            <b-tab>
              <div slot="title" class="text-dark">
                <i class="fa fa-leaf"></i> Equipements
              </div>
            </b-tab>
          </b-tabs>
        </b-tab>
      </b-tabs>
  </div>
</template>

<script>
import { Header as AppHeader } from '@coreui/vue'
import DefaultHeaderDropdownAccount from '../../containers/DefaultHeaderDropdownAccount.vue'
import { Api, env, Toast } from "../../helpers";
import readXlsxFile from 'read-excel-file'
import GeneratedCultureContainer from '../exploitation/cultures/GeneratedCultureContainer.vue';

export default {
  name: "SuperAdminHome",

  components: {
    AppHeader,
    DefaultHeaderDropdownAccount,
    GeneratedCultureContainer
  },

  data() {
    return {
      operationInProgress: false,
      actions: [],
      firstRender: true,
      fetchingCultures: true,
      cultures: [],
      cathegories: "piaCultures",
      displayedCulture: {},
      adminTapOpen: 0,
      csvCreateCultureFile: null,
      culturesToAdd: [],
      fetchingCreateCultureFile: false,

      csvCreateCultureStepFile: null,
      cultureStepsToAdd: [],
      fetchingCreateCultureStepFile: false,


      csvCreateActivityFile: null,
      activitiesToAdd: [],
      fetchingCreateActivityFile: false,

    }
  },
  created() {
      Api.get("/admin/cultures")
      .then(res => {
        if(!res.data.data){
          Toast.error("Erreur de connexion au serveur")
        }else{
          this.cultures = res.data.data
          this.fetchingCultures = false
        }
      })
      .catch(e => Toast.error("Erreur de connexion au serveur"))
  },
  mounted() {
  },
  watch: {
    cultures(newValue){
      if(newValue){
        this.$nextTick(()=>{
          this.selectFistCulture()
 })
      }

    }
  },
  computed: {
    csvTemplateCreateCultureUrl() {
      return env('ADMIN_CREATE_CULTURE')
    },
    csvFileCreateCultureState() {
      return this.csvCreateCultureFile == null || this.isValidCreateCultureCsv(this.culturesToAdd) ? null : false
    },

    csvTemplateCreateCultureStepUrl() {
      return env('ADMIN_CREATE_CULTURE_STEP')
    },
    csvFileCreateCultureStepState() {
      return this.csvCreateCultureStepFile == null || this.isValidCreateCultureStepCsv(this.cultureStepsToAdd) ? null : false
    },

    csvTemplateCreateActivityUrl() {
      return env('ADMIN_CREATE_ACTIVITY')
    },
    csvFileCreateActivityState() {
      return this.csvCreateActivityFile == null || this.isValidCreateActivityCsv(this.activitiesToAdd) ? null : false
    },

  },
  methods: {
    getScrollbarWidth() {
      // Creating invisible container
      const outer = document.createElement('div');
      outer.style.visibility = 'hidden';
      outer.style.overflow = 'scroll'; // forcing scrollbar to appear
      outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
      document.body.appendChild(outer);

      // Creating inner element and placing it in the container
      const inner = document.createElement('div');
      outer.appendChild(inner);

      // Calculating difference between container's full width and the child width
      const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

      // Removing temporary elements from the DOM
      outer.parentNode.removeChild(outer);

      return scrollbarWidth;
        
    },
    switchCathegorie(cathegorieName){
      switch(cathegorieName) {
        case "piaCultures":
          this.actions = []
          break
        case "approuvedCultures":
          this.actions = ['dismiss']
          break
        case "unHandledCultures":
        this.actions = ['approve', 'dismiss']
          break
        case "refusedCultures":
        this.actions = ['approve']
          break
        }


      this.cathegories = cathegorieName

      // Attendre que le DOM soit mis à jour
      this.$nextTick(() => {
          this.selectFistCulture()
      });
    },
    selectFistCulture(){
      let displayedCultureTabs = this.$refs.displayedCultureTabs.$el;          
      let firstTab = displayedCultureTabs.querySelector('[aria-selected]');          
      firstTab.click()
    },
    updateCulture(cultureOnUpdating, newIsOwn, destinationCathegorie){
      this.operationInProgress = true
      Api.post("/admin/updateCulture", {
        cultureId: cultureOnUpdating.id,
        newIsOwn: newIsOwn
      })
      .then(res =>{
        if (res.data.status === 'success' && res.data.data) {
          this.cultures[this.cathegories] = this.cultures[this.cathegories].filter(culture => (culture.id != cultureOnUpdating.id))
          cultureOnUpdating.isOwn = newIsOwn
          this.cultures[destinationCathegorie].push(cultureOnUpdating)

          if(newIsOwn == 4){
            Toast.success("La culture a bien été approuvée")
          }else if(newIsOwn == 5){
            Toast.success("La culture a bien été Rejettée")
          }
        }else{
          Toast.error("Une erreur est survenue.")
        }
      })
      .catch(e => {
        console.log(e)
        Toast.error("Erreur de connexion au serveur")
      })
      .then(()=>{
        if(this.cultures[this.cathegories].length != 0){
          this.selectFistCulture()
        }
        this.operationInProgress = false
      })
    },
    denayCulture(culture) {
      this.updateCulture(culture, 5, "refusedCultures")
    },
    acceptCulture(culture) {
      this.updateCulture(culture, 4, "approuvedCultures")
    },
    switchDisplayedCulture(culture) {
      this.displayedCulture = culture
      //TODO: gérer l'affichage des bouttons de moderation
    },
    isValidCreateCultureCsv(cultures) {
      try {
        if (cultures.length < 1) return null
        for (let i = 0; i < cultures.length; i++) {
          if (
            cultures[i][0].length <= 2
          )
            return null
        }
      } catch (e) {
        return null
      }

      return true
    },
    csvCreateCultureFileChanged(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length)
        return;

      readXlsxFile(files[0]).then((rows) => {
        rows.shift()
        this.culturesToAdd = rows
        console.log(rows)
      })
    },
    onSubmitCreateCultureFile() {

      if (this.csvCreateCultureFile == null || !this.isValidCreateCultureCsv(this.culturesToAdd))
        return

      this.fetchingCreateCultureFile = true

      Api.post('/system/culture/create/many', {
        cultures: this.culturesToAdd.map(item => ({
          name: item[0],
          comment: item[1],
        }))
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            Toast.success("les differentes cultures ont été crées avec succès")
          } else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingCreateCultureFile = false
          this.csvCreateCultureFile = null
        })

    },


    isValidCreateCultureStepCsv(cultureSteps) {
      try {
        if (cultureSteps.length < 1) return null
        for (let i = 0; i < cultureSteps.length; i++) {
          if (
            cultureSteps[i][0].length <= 2
          )
            return null
        }
      } catch (e) {
        return null
      }

      return true
    },
    csvCreateCultureStepFileChanged(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length)
        return;

      readXlsxFile(files[0]).then((rows) => {
        rows.shift()
        this.cultureStepsToAdd = rows
        console.log(rows)
      })
    },
    onSubmitCreateCultureStepFile() {

      if (this.csvCreateCultureStepFile == null || !this.isValidCreateCultureStepCsv(this.cultureStepsToAdd))
        return

      this.fetchingCreateCultureStepFile = true

      Api.post('/system/culture-step/create/many', {
        cultures: this.cultureStepsToAdd.map(item => ({
          name: item[0],
          comment: item[1],
        }))
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            Toast.success("les differentes étapes de cultures ont été crées avec succès")
          } else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingCreateCultureStepFile = false
          this.csvCreateCultureStepFile = null
        })

    },


    isValidCreateActivityCsv(activities) {
      try {
        if (activities.length < 1) return null
        for (let i = 0; i < activities.length; i++) {
          if (
            activities[i][0].length <= 2 ||
            (activities[i][1] <= 0 || parseInt(activities[i][1]) == NaN) ||
            (activities[i][2] <= 0 || parseFloat(activities[i][2]) == NaN) ||
            (activities[i][3] <= 0 || parseInt(activities[i][3]) == NaN)
          )
            return null
        }
      } catch (e) {
        return null
      }

      return true
    },
    csvCreateActivityFileChanged(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length)
        return;

      readXlsxFile(files[0]).then((rows) => {
        rows.shift()
        this.activitiesToAdd = rows
        console.log(rows)
      })
    },
    onSubmitCreateActivityFile() {

      if (this.csvCreateActivityFile == null || !this.isValidCreateActivityCsv(this.activitiesToAdd))
        return

      this.fetchingCreateActivityFile = true

      Api.post('/system/activity/create/many', {
        cultures: this.activitiesToAdd.map(item => ({
          name: item[0],
          comment: item[4],
          duration: item[1],
          cost: item[2],
          personNumber: item[3],
          equipments: null,
          inputs: null,
        }))
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            Toast.success("les differentes activités ont été crées avec succès")
          } else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingCreateActivityFile = false
          this.csvCreateActivityFile = null
        })

    },


  }
}
</script>

<style scoped>
.button_container{
  padding: 0px calc(2rem + 4px) 3rem 0px;
  margin: 0px;
  display: flex;
  gap: 1.5rem;
  justify-content: end;
}
.display_culture_container{
  position: relative;
  height: 60vh;
  overflow: hidden;
}
#main_container{
  height: 100vh;
  overflow-y: auto;
  background: red;
}
.no_data_container{
  display: flex;
  min-width: 100%;
  height: 70vh;
  font-size: x-large;
  font-family: "Segoe UI Historic";
  justify-content: center;
  align-items: center;
  color: grey;
}

.no_data_container > p{
  margin: 0;
  width: max-content;
}

</style>